// @import '~@spordle/ui-kit/assets/default/scss/all/bootstrap/functions';
// @import '~@spordle/ui-kit/assets/default/scss/all/bootstrap/variables';
// @import '~@spordle/ui-kit/assets/default/scss/all/custom/variables';

$fileViewerDark: #121519;
$white: #fff;

.Bar {
    left: 0;
    opacity: 0;
    padding: .75rem;
    position: fixed;
    right: 0;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
    z-index: 10;
    visibility: hidden;

    &.IsVisible,
    &:hover {
        opacity: 1;
        visibility: visible;
    }

    &Top {
        align-items: center;
        background: linear-gradient(to bottom, rgba($fileViewerDark,1), rgba($fileViewerDark,0));
        color: rgba($white, 0.65);
        display: flex;
        justify-content: space-between;
        top: 0;
        pointer-events: none;

        &FileInfo {
            margin-right: auto;
            pointer-events: initial;
        }

        &FileName {
            color: $white;
            font-weight: bold;
            font-size: 1.05rem;
        }

        &Btns {
            white-space: nowrap;
            & button, a{
                pointer-events: initial;
            }
        }
    }

    &Bottom {
        background: linear-gradient(to top, rgba($fileViewerDark, 1), rgba($fileViewerDark,0));
        bottom: 0;
        text-align: center;
        pointer-events: none;
        & button{
            pointer-events: initial;
        }
    }
}

.Container {
    background-color: rgba($fileViewerDark,0.925);
    bottom: 0;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 999999;
}

.Pdf {
    &Page {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
    }
    &FirstPage {
        margin-top: 2rem;
    }
}

.SVG {
    background-color: $white;
}

.ZoomLevel {
  color: $white
}

.Button {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  padding: .4rem .8rem;
  font-size: 1.6rem;
  line-height: 1.5;
  border-radius: .7rem;
  cursor: pointer;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:hover{
    text-decoration: none;
  }
}

.ButtonSecondary {
  color: $white;
  background-color: #6c757d;
  border-color: #6c757d;
  box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, .075);

  &:hover{
    background-color: #5a6268;
    border-color: #545b62;
  }
}

.ButtonDark {
  color: $white;
  background-color: #3e3e3e;
  border-color: #3e3e3e;
  box-shadow: inset 0 1px 0 #ffffff26, 0 1px 1px rgba(0, 0, 0, .075);

  &:hover{
    background-color: #2b2b2b;
    border-color: #252525;
  }
}

.Bootstrap {
  &_MR2,
  &_MX2 {
    margin-right: .8rem !important;
  }
  &_MR1,
  &_MX1,
  &_ML1 {
    margin-right: .4rem !important;
  }
  &_MB3,
  &_MY3 {
    margin-bottom: 1.6rem !important;
  }
  &_LineHeight1 {
    line-height: 1;
  }
  &_TextMuted {
    color: #969696 !important;
  }
}

.Preview {
    display: inline-block;
    vertical-align: middle;
    flex-shrink: 0;
    margin: auto;
    max-width: none;
    position: relative;
    image-rendering: pixelated;

    &Img{
        user-select: none;
    }
    &Video{
        max-width: 100%;
        max-height: 100%;
    }
    &Container {
        display: flex;
        min-height: 100%;
        position: relative;
        width: 100% !important;

        &Zoom{
            min-height: 100%;
            position: relative;
            width: 100% !important;
            height: 100% !important;
        }

        &.WithText {
            min-width: min-content;
            user-select: initial;

            & :global(.react-transform-component) {
                width: 100%;
                height: auto;
            }
        }
    }
    &Text {
        white-space: pre;
        min-width: 100%;
    }
}

.Icon {
    font-size: 150px;
    line-height: 1;
}

.NoPreview {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    color: $white;

    &Text {
      white-space: pre;
      font-size: large;
    }

    &Icon {
      vertical-align: middle;
    }
}

.Loading {
    animation-name: loadingDocAnimation;
    animation-timing-function: ease-in-out;
    animation-duration: 1.35s;
    animation-iteration-count: infinite;
    font-size: 200px;
}

@keyframes loadingDocAnimation {
    0% {
        opacity: 0.4;
    }
    50% {
        opacity: 0.95;
    }
    100% {
        opacity: 0.4;
    }
}
